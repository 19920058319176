
















































import { Component, Vue } from 'vue-property-decorator'
import { getCHageRecordList } from '_user/api'

import moment from 'moment'
import { LoyoutModule } from '@/store/modules/layout'
import { getUserGameRecord } from '@/pages/videoGame/api'
import { CommonModule } from '@/store/modules/common'
import { unitFormat } from '@/common/utils'
import DayLottory_icon from '@/pages/videoGame/assets/record/dayLottory_icon.png'
import RankList_icon from '@/pages/videoGame/assets/record/rankList_icon.png'

import VipSatday_icon from '@/pages/videoGame/assets/record/vipSatday_icon.png'

@Component({})
export default class extends Vue {
  unitFormat = unitFormat
  list: any = []
  loading = false
  finished = false
  showSke = false
  refreshing = false
  pageInfo = {
    currPage: 0,
    pageSize: 20,
    total: 0,
    totalPage: 0
  }
  getDate(t: number) {
    return moment(t * 1000).format('YYYY-MM-DD HH:mm:ss')
  }
  async getRecordList() {
    const params = {
      page: this.pageInfo.currPage + 1,
      limit: this.pageInfo.pageSize,
      uid: CommonModule.userId
    }

    const { data } = await getUserGameRecord(params)
    if (params.page == 1) {
      this.list = []
    }
    this.loading = false
    this.showSke = false
    this.pageInfo = data.pageInfo
    this.list.push(...data.list)
    this.refreshing = false

    if (this.list.length == this.pageInfo.total) {
      this.finished = true
    }
  }
  showPic(item: any) {
    if (item.type == 2) {
      return RankList_icon
    } else if (item.type == 3) {
      return DayLottory_icon
    } else if (item.type == 4) {
      return VipSatday_icon
    } else {
      return item.machineSmallPic
    }
  }
  onRefresh() {
    // 清空列表数据

    this.finished = false
    this.pageInfo.currPage = 0
    this.showSke = true
    // 重新加载数据
    // 将 loading 设置为 true，表示处于加载状态
    //this.loading = true
    this.getRecordList()
  }
  mounted() {
    const title: any = this.$t('积分记录')
    LoyoutModule.setTitle(title)
    this.getRecordList()
  }
}
